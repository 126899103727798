import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import api from '../../../api/Apis';
import Slider from "react-slick";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { IconButton } from '@material-ui/core';
import HomeComponent from './HomePageStyle';
import { SecondSection } from 'src/pages/[page]/product/common';
import ClassNames from 'classnames';
import ProvenComponent from './provenComponent';
import MailingComponent from '@Components/MailingComponent';
import { Helmet } from 'react-helmet';
import ClaimGiftPopup from '@Components/ClaimGiftPopup';

export const AddCardButtonText = (is_stock_available, costtype, id) => {
    if (is_stock_available === 'True') {
        const findData = _.find(costtype, { id: id });
        if (findData?.id === id && findData?.value === "AutoShip") {
            return 'Subscribe';
        }
        return 'Add to Cart'
    } else {
        return "Sold Out"
    }
}

const Home = ({ icon, homePageData, setshowloader, minCartAutoshipCheck, smartShipCheck, setcounts, setInnerLoader, innerLoader, subDomain, USER_DATA, ...props }) => {

    const [productData, setProductData] = useState([]);
    const [costtype, setcosttype] = useState([]);
    const [productvariantid, setproductvariantid] = useState();
    const [Logintoken, setToken] = useState()
    const [product, setproduct] = useState();
    const [active, setActive] = useState(false);
    const [getTokenDat, setTokenDat] = useState()
    const [isActive, setIsActive] = useState("false");
    const [addNotify, setAddNotify] = useState('')
    const [openPopup, setOpenPopup] = useState(false);
    const [quantityCheck, setQuantityCheck] = useState([]);
    const [openClaimGift, setOpenClaimGift] = useState(false);
    const [storeReferral, setStoreReferral] = useState();
    const router = useRouter()
    const store = router?.query?.page || "us";
    const { id, name, page } = router.query;

    const REFERRAL_CODE = router?.query['referral-code'];

    useEffect(() => {
        const token = localStorage.getItem("Token");
        setToken(token);
        getAllProduct(store);
    }, [store])

    const FIND_PRODUCT_ID = productData?.find((row) => row?.id === "35")?.id;
    const FIND_PRODUCT_ID2 = productData?.find((row) => row?.id === "7")?.id;

    const getAllProduct = async (store) => {
        // setInnerLoader(true)
        await api.getAllProduct(store).then((res) => {
            const allvalues = _.map(res?.data?.products, (row) => ({
                id: row.id,
                value: "AutoShip",
            }));
            setcosttype(allvalues);
            setProductData(res?.data?.products);
        }).catch(() => {
        })
    }
    const sliders = useRef();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // vertical: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // vertical: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // vertical: true,
                    initialSlide: 1

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    // vertical: true,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 360,
                settings: {
                    slidesToShow: 1,
                    // vertical: true,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const NextArrow = ({ onClick }) => (
        <div className="custom-arrow custom-next" onClick={onClick}>
            <div className="next-arrow slick-arrow">
                <img src="/images/Arrowright.png" />
            </div>
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div className="custom-arrow custom-prev" onClick={onClick}>
            <div className="prev-arrow slick-arrow">
                <img src="/images/Arrowleft.png" />
            </div>
        </div>
    );

    const slidersBanner = useRef();
    const settings1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        centerMode: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]

    };


    function next() {
        sliders?.current?.slickNext();
    }
    function previous() {
        sliders?.current?.slickPrev();
    }

    const handleAddCart = (e, quantity) => {
        const formData = {
            product_id: e.target.id,
            variant_id: productvariantid,
            token: Logintoken,
            quantity: quantity,
            is_autoship: _.find(costtype, { id: e.target.id })?.value === 'AutoShip' ? true : false
        };

        api.addToCart(formData).then((res) => {
            if (res?.data?.code === 1) {
                setAddNotify('Added To Cart')
                setTimeout(() => {
                    setAddNotify('')
                }, 2000);
                props?.setshowminicart(true)

            }
        })
    }

    const handleToggle = (id) => {
        if (isActive === id) {
            setIsActive('');
        } else {
            setIsActive(id);
        }
    };
    const Image = !(homePageData?.content[0]?.discount_banner_image_desktop) ? process.env.API_URL + homePageData?.content[0]?.banner_video_BGimage : homePageData?.content[0]?.discount_banner_image_desktop;
    const handleClick = (id) => {
        setInnerLoader(true)
        if (id === "8") {
            router.push("us/product/Super_Prime_120/8/");
            setInnerLoader(false)
        }
        if (id === "35") {
            router.push("us/product/BioticKaire+/35/");
            setInnerLoader(false)
        }
        if (id === "4") {
            router.push("us/product/Maritime_Prime_180/4/");
            setInnerLoader(false)
        }
        if (id === "7") {
            router.push("us/product/SilverKaire/7/");
            setInnerLoader(false)
        }
    }
    const homePageSliderData = {
        imageCrousel: [
            {

                banner_image_product_id: 1,
                rating: 5.0,
                name: 'Leight',
                text: 'This is a great product that offers excellent value for money.'
            },
            {

                banner_image_product_id: 2,
                rating: 5.0,
                name: 'Gale',
                text: 'An amazing product with top-notch features and performance.'
            },
            {

                banner_image_product_id: 3,
                rating: 5.0,
                name: 'John',
                text: 'The best product in its category with unbeatable quality.'
            },
            {

                banner_image_product_id: 4,
                rating: 5.0,
                name: 'Proven',
                text: 'A solid choice for anyone looking for reliability and efficiency.'
            },
            {

                banner_image_product_id: 5,
                rating: 5.0,
                name: 'Chris',
                text: 'Exceptional performance and design that stands out from the crowd.'
            }
        ]
    };

    const BiotickKaireVideos = [
        {
            urlData: `https://admin.shopkaire.com///media/products/2024/08/05/BioticKaire-min-2.png`,
            thumbnail: 'https://admin.shopkaire.com///media/products/2024/08/05/BioticKaire-min-2.png',
            isImage: true,
        },
        {
            urlData: `https://player.vimeo.com/video/1053933481?autoplay=false&loop=0&autopause=1&controls=true`,
            thumbnail: 'https://i.vimeocdn.com/video/1922605922-8e262966734fde637e0ca45b6e6b94814ab95670c5bedca4e5142585a59690d5-d?mw=300&mh=536',
            title: "Maritime Prime"
        },
        {
            urlData: "https://player.vimeo.com/video/1010759116?autoplay=false&loop=0&autopause=1&controls=true",
            thumbnail: 'https://i.vimeocdn.com/video/1928181056-b629ce9815bb516a499292326d10b82dd809846777d92c15c1a6e8b3cabf658e-d?mw=300&mh=536',
            title: "BioticKaire+"
        },
        {
            urlData: "https://player.vimeo.com/video/1055079623?autoplay=false&loop=0&autopause=1&controls=true",
            thumbnail: 'https://i.vimeocdn.com/video/1922540731-c2e48aabf635b4dda5fc2ea611e4b6a6c45d913828fa491293312c7e1e4f4439-d?mw=300&mh=536',
            title: "SilverKaire"
        },

    ]

    const BiotickKaireVideo2 = [
        {
            urlData: `https://admin.shopkaire.com///media/products/2024/08/05/BioticKaire-min-2.png`,
            thumbnail: 'https://admin.shopkaire.com///media/products/2024/08/05/BioticKaire-min-2.png',
            isImage: true,
        },
        {

            urlData: `https://player.vimeo.com/video/1005957632?autoplay=false&loop=0&autopause=1&controls=true`,
            thumbnail: 'https://i.vimeocdn.com/video/1922605922-8e262966734fde637e0ca45b6e6b94814ab95670c5bedca4e5142585a59690d5-d?mw=300&mh=536',
        },
        {
            urlData: "https://player.vimeo.com/video/1010759116?autoplay=false&loop=0&autopause=1&controls=true",
            thumbnail: 'https://i.vimeocdn.com/video/1928181056-b629ce9815bb516a499292326d10b82dd809846777d92c15c1a6e8b3cabf658e-d?mw=300&mh=536',
        },
        {
            urlData: "https://player.vimeo.com/video/1005901656?autoplay=false&loop=0&autopause=1&controls=true",
            thumbnail: 'https://i.vimeocdn.com/video/1922540731-c2e48aabf635b4dda5fc2ea611e4b6a6c45d913828fa491293312c7e1e4f4439-d?mw=300&mh=536',
        },

    ]
    const Prime180kaireVideos = [
        {
            urlData: "https://player.vimeo.com/video/1053933481?autoplay=false&loop=0&autopause=1&controls=true",
            thumbnail: '/images/man-prime-pic.jpg',
        },

    ]
    const StarRating = ({ rating }) => {
        const totalStars = 5;
        const filledStars = Math.floor(rating);
        const halfStar = rating % 1 !== 0;

        return (
            <div className="star-rating">
                {[...Array(totalStars)].map((_, index) => {
                    if (index < filledStars) {
                        return <span key={index} className="star filled">★</span>;
                    }
                    return halfStar && index === filledStars ? (
                        <span key={index} className="star half-filled">☆</span>
                    ) : (
                        <span key={index} className="star">☆</span>
                    );
                })}
            </div>
        );
    };

    const Add = (e, values) => {
        let a = e?.currentTarget?.value;
        let max = values?.quantity;
        if (+(max) > 0 && +(a) < +(max)) {
            let data = _.find(quantityCheck, { id: values?.id });
            if (data) {
                let rejectData = _.reject(quantityCheck, data);
                setQuantityCheck([...rejectData, { id: values?.id, qty: data?.qty + 1 }]);
            } else {
                setQuantityCheck([...quantityCheck, { id: values?.id, qty: (+a) + 1 }])
            }
        }
    }
    // decrease product qty
    const Sub = (e, values) => {
        let a = e.currentTarget.value;
        if (a > 1) {
            let data = _.find(quantityCheck, { id: values?.id });
            if (data) {
                let rejectData = _.reject(quantityCheck, data);
                setQuantityCheck([...rejectData, { id: values?.id, qty: data?.qty - 1 }]);
            } else {
                setQuantityCheck([...quantityCheck, { id: values?.id, qty: (+a) - 1 }])
            }

        }
    }

    useEffect(() => {
        const displayPopupKey = sessionStorage.getItem('PopUpDispaly');
        if (!displayPopupKey) {
            setTimeout(() => {
                setOpenPopup(true);
            }, 3000);
            sessionStorage.setItem('PopUpDispaly', 'true');
        }
        if (REFERRAL_CODE) {
            sessionStorage.setItem('referral-code', true);
            const refCode = sessionStorage.getItem('referral-code');
            setStoreReferral(refCode);
        }
        return () => {
        };
    }, [REFERRAL_CODE, router]);

    useEffect(() => {
        if (REFERRAL_CODE?.length > 0) {
            setOpenClaimGift(true);
        };
    }, [router])



    return (
        <>
            <Helmet>
                <link rel="preload" href="/images/banner-layer.jpg" as="image" />
            </Helmet>
            <HomeComponent promotionBannerMobile={homePageData?.content[0]?.discount_banner_image_mobile}>
                {homePageData &&
                    <div className="home-page" >
                        <div className='welcome-section'>
                            <div className="welcome_banner">
                                <div className='container'>
                                    <div className='main-banner-section flex'>
                                        <div className='left-side-image'>
                                            <img src='/images/banner-layer.jpg' alt="Nature's Elite" />
                                            <h3 className='top-h3 heading-font'>
                                                <img src='/images/Natures Elite.png' />
                                            </h3>
                                        </div>
                                        <div className='right-side-text'>
                                            <div className='inside-right-sec'>
                                                <h2 className='heading-font home-heading-common'>Pioneering the Gold Standard in Purity and Potency</h2>
                                                <h3 className=''><b>NATURAL INGREDIENTS FOR YOUR HEALTH</b></h3>
                                                <p>We are setting a new benchmark in the industry. Each product is crafted with meticulous care, using only the finest, naturally-sourced ingredients to ensure that what you
                                                    put into your body is as close to nature's intent as possible.
                                                </p>
                                                <div className="btn-link">
                                                    <Link className="nav-link" exact href={`/${store}/allProduct`}>
                                                        <a className="link">Shop Now </a>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='biotickakire-video-sec'>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="biotickakire-video-wrap">
                                            <h2>POTENT, NATURAL NOURISHMENT FOR FEELING YOUR BEST.</h2>
                                            <div className="biotickakire-video-innerwrap">
                                                {BiotickKaireVideos?.slice(1)?.map((video, index) => {
                                                    return (
                                                        <>
                                                            <div className='biotickakire-video'>
                                                                <div className="biotickakire-video-maon-section" key={index}>
                                                                    <iframe src={video?.urlData} width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen ></iframe>
                                                                </div>
                                                                <p>{video?.title}</p>
                                                            </div>
                                                        </>

                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slider-main slider-main-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="slider-main mt-3">
                                            <div className="carousel slide media-carousel" id="media">
                                                <div className="carousel-inner">
                                                    <div className="silck-slide-home">
                                                        <IconButton className="button_pre" onClick={previous}><AiOutlineLeft /></IconButton>
                                                        <Slider ref={sliders} {...settings}>
                                                            {productData?.map((values, index) => {
                                                                if (values?.retail_open === true) {
                                                                    let names = values?.name.replaceAll('25%', '')
                                                                    let catname1 = names.replaceAll('\t', '')
                                                                    let catname = catname1.replaceAll(' ', '_')
                                                                    let autoShiptValide = (costtype?.length > 0 && _.find(costtype, { id: values?.id })?.value === "AutoShip")
                                                                    let checkQuantity = _.find(quantityCheck, { id: values?.id })
                                                                    return (
                                                                        <li key={index + 1}>
                                                                            <div className="product-main" key={index + 1}>
                                                                                <Link href={`/${store}/product/${catname}/${values?.id}`}>
                                                                                    <a className="thumbnail">
                                                                                        <img alt="" src={
                                                                                            values?.product_images[0]?.image
                                                                                                ?
                                                                                                `${process.env.API_URL}/${values?.product_images[0]?.image}`
                                                                                                :
                                                                                                "/images/no-image.jpg"}
                                                                                        />
                                                                                    </a>
                                                                                </Link>

                                                                                <h5>{values?.name}</h5>
                                                                                <div className={!(isActive === values?.id) ? "app_active" : "app_active"}>
                                                                                </div>
                                                                                <>
                                                                                    <SecondSection data={{
                                                                                        costtype,
                                                                                        setcosttype,
                                                                                        minCartAutoshipCheck,
                                                                                        smartShipCheck,
                                                                                        id: values?.id,
                                                                                        productCost: values?.cost_price,
                                                                                        productSmartShip: values?.autoship_cost_price
                                                                                    }} />
                                                                                    <div className="home-main-qty-sec">
                                                                                        <div className="box">
                                                                                            <div id="qty">
                                                                                                <button type="button"
                                                                                                    name={`${values?.id},${null}`}
                                                                                                    id={values?.id}
                                                                                                    className="sub-btn"
                                                                                                    value={(checkQuantity) ? checkQuantity.qty : 1}
                                                                                                    onClick={(e) => { Sub(e, values) }}>-</button>
                                                                                                <input
                                                                                                    name={`${values?.id},${null}`}
                                                                                                    type="text"
                                                                                                    className="get"
                                                                                                    value={(checkQuantity) ? checkQuantity?.qty : 1}
                                                                                                    list={values?.quantity}
                                                                                                    max={values?.quantity}
                                                                                                    readOnly
                                                                                                />
                                                                                                <button type="button"
                                                                                                    name={`${values?.id},${null}`}
                                                                                                    id={values?.id} className="add-btn"
                                                                                                    value={(checkQuantity) ? checkQuantity.qty : 1}
                                                                                                    onClick={(e) => { Add(e, values) }}>+</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <button className={ClassNames("add_cart main_btn_common", { 'disable_true': !(values?.is_stock_available === "True") })}
                                                                                        value={autoShiptValide ? true : false}
                                                                                        id={values.id}
                                                                                        disabled={!(values?.is_stock_available === "True")}
                                                                                        onClick={(e) => handleAddCart(e, (checkQuantity) ? checkQuantity.qty : 1)}>{
                                                                                            AddCardButtonText(values?.is_stock_available, costtype, values?.id)}
                                                                                    </button>
                                                                                    {addNotify && <span className="error" style={{ color: 'green' }}>{addNotify}</span>}
                                                                                </>


                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }
                                                            })}
                                                        </Slider>
                                                        <IconButton className="button_next" onClick={next}><AiOutlineRight /></IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn-shop-all">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="welcome-section Prime-sec">
                            <div className="welcome_banner welcome_banner_2 ">
                                <div className='container'>
                                    <div className='main-banner-section flex'>
                                        <div className='left-side-image'>
                                            <img src='/images/maritime.jpg' />
                                        </div>
                                        <div className='right-side-text'>
                                            <div className='inside-right-sec'>
                                                <h2 className='heading-font home-heading-common'>Maritime Prime™
                                                    Pycnogenol<sup>®</sup> French Maritime Pine Bark Extract</h2>
                                                <p>Pycnogenol<sup>®</sup> is an amazing gift from nature, but not all Pycnogenol<sup>®</sup> products are created equally. Maritime Prime Pycnogenol® does not contain inexpensive fillers to bolster profitability.  Our proprietary blend incorporates only the finest plant-based ingredients and enzymes, ensuring peak effectiveness and absorption – making it a truly unique and incomparable product.
                                                </p>
                                                {Prime180kaireVideos?.slice(0)?.map((video, index) => {
                                                    return (
                                                        <div className="prime-video-data mobile-view" key={index}>
                                                            <iframe src={video?.urlData} width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen;"
                                                                allowfullscreen ></iframe>
                                                        </div>

                                                    )
                                                })
                                                }
                                                <div className='inner-img-div'>
                                                    <img src='/images/product-home.webp' />
                                                </div>
                                                <div className="btn-link">
                                                    <button className="add_cart main_btn_common" onClick={() => { setInnerLoader(true), router?.push("/us/product/Super_Prime_120/8/") }}>Shop now</button>
                                                </div>
                                            </div>
                                            {Prime180kaireVideos?.slice(0)?.map((video, index) => {
                                                return (
                                                    <div className="prime-video-data desktop-view" key={index}>
                                                        <iframe src={video?.urlData} width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen;"
                                                            allowfullscreen ></iframe>
                                                    </div>

                                                )
                                            })
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='proven-benefits section'>
                            <div className='container'>
                                <ProvenComponent />
                            </div>
                        </div>

                        <div className='welcome-section biotickaire-sec'>
                            <div className="welcome_banner">
                                <div className='container'>
                                    <div className='main-banner-section flex'>
                                        <div className='left-side-image'>
                                            <img src='/images/biotickaire-banner.jpg' />
                                        </div>
                                        <div className='right-side-text'>
                                            <div className='inside-right-sec'>
                                                <h2 className='heading-font home-heading-common'>BioticKaire+ Prebiotics, Probiotics, Postbiotics and Digestive Enzymes</h2>
                                                <p>BioticKaire+ is gut health, redefined with our groundbreaking 4-IN-1 formula - a fusion of Prebiotics, Probiotics, Postbiotics, and Enzymes to elevate digestion, supercharge immunity, and unlock superior nutrient absorption.
                                                </p>
                                                <div className="btn-link">
                                                    <button className="add_cart main_btn_common" id={FIND_PRODUCT_ID}
                                                        onClick={(e) => handleAddCart(e, 1)}>
                                                        Add to Cart
                                                    </button>
                                                </div>
                                                <div className='inner-img-video-wrapper'>
                                                    <img src='/images/BioticKaire-min-2.webp' width="180" height="279" alt="Biotic Kaire Image" />
                                                    <div className="btn-link mobile-view">
                                                        <button className="add_cart main_btn_common" id={FIND_PRODUCT_ID}
                                                            onClick={(e) => handleAddCart(e, 1)}>
                                                            Add to Cart
                                                        </button>
                                                    </div>
                                                    <div className="biotickakire-video-innerwrap">
                                                        {BiotickKaireVideo2?.slice(1)?.map((video, index) => {
                                                            return (
                                                                <div className="biotickakire-video" key={index}>
                                                                    <iframe src={video?.urlData} width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen;"
                                                                        allowfullscreen ></iframe>
                                                                </div>

                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="welcome-section silverkaire-sec">
                            <div className="welcome_banner welcome_banner_2 ">
                                <div className='container'>
                                    <div className='main-banner-section flex'>
                                        <div className='left-side-image'>
                                            <img src='/images/silver-banner.webp' className='lazy-image' alt='banner silver' />
                                        </div>
                                        <div className='right-side-text'>
                                            <div className='inside-right-sec'>
                                                <h2 className='heading-font home-heading-common'>SilverKaire Colloidal Silver 10PPM Silver Solution</h2>
                                                <p>Crafted with nano-sized silver particles and cutting-edge technology, our 99.9% pure solution delivers unmatched bioavailability and efficacy. Free from impurities or stabilizers, it offers powerful antimicrobial support for your immune system and skin health—all while being safe for long-term use. Experience the purity that sets a new standard.
                                                </p>
                                                {BiotickKaireVideos?.slice(3)?.map((video, index) => {
                                                    return (
                                                        <div className="prime-video-data mobile-view" key={index}>
                                                            <iframe src={video?.urlData} width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen;"
                                                                allowfullscreen ></iframe>
                                                        </div>

                                                    )
                                                })
                                                }
                                                <div className='inner-img-div'>
                                                    <img src='/images/silver-img.webp' className='lazy-image' alt='silver' />
                                                </div>
                                                <div className="btn-link">
                                                    <button className="add_cart main_btn_common" id={FIND_PRODUCT_ID2}
                                                        onClick={(e) => handleAddCart(e, 1)}>Add to Cart</button>
                                                </div>

                                            </div>
                                            {BiotickKaireVideos?.slice(3)?.map((video, index) => {
                                                return (
                                                    <div className="silver-video-data prime-video-data desktop-view" key={index}>
                                                        <iframe src={video?.urlData} width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen;"
                                                            allowfullscreen ></iframe>
                                                    </div>

                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="banner-slider">
                            <div className="container">
                                <div className="slider-wrap">
                                    <h2 className='text-center text-black'>REVIEWS</h2>
                                    <Slider ref={slidersBanner} {...settings1}>
                                        {homePageSliderData?.imageCrousel && homePageSliderData?.imageCrousel.map((row) => (
                                            <div className='image-crousel' key={row.banner_image_product_id}>
                                                <div className="product-info text-white">
                                                    <StarRating rating={row.rating} />
                                                    <h3>{row.name}</h3>
                                                    <p>{row.text}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className='guarrented-section'>
                            <div className='container'>
                                <div className='garreanted-inner-div'>
                                    <div className='top-image'>
                                        <img src='/images/guarrented-logo.png' alt='guarrenty' />
                                    </div>
                                    <p className='first-text'>
                                        No Matter What The Reason, You Have 90 Days From The Date of
                                        Purchase to Return The Product … No Questions Asked!
                                    </p>
                                    <p className='last-text'>All you have to do is <a href="mailto:support@kaire.com">Contact Customer Support</a> telling them that you wish to return your product…
                                        send us the original containers and pay the postage… It Is as Easy As That!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </HomeComponent>
            {(!Logintoken && !storeReferral) ? (
                <MailingComponent
                    open={openPopup}
                    setOpenPopup={setOpenPopup}
                    setInnerLoader={setInnerLoader}
                    innerLoader={innerLoader}
                    REFERRAL_CODE={REFERRAL_CODE}
                />
            ) : (!Logintoken && storeReferral) ? (
                <ClaimGiftPopup
                    openClaimGift={openClaimGift}
                    setOpenClaimGift={setOpenClaimGift}
                    subDomain={subDomain}
                    setInnerLoader={setInnerLoader}
                    innerLoader={innerLoader}
                    REFERRAL_CODE={REFERRAL_CODE}
                    USER_DATA={USER_DATA}
                />
            ) : null
            }

        </>
    )
}
export default Home;


