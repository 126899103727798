import React, { useState, useCallback } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import styled from "styled-components";
import Image from "next/image";
import { useFormik } from "formik";
import api from "@api/Apis";
import * as Yup from "yup";

const DialogStyle = styled.div`
  .MuiPaper-root {
    max-width: 1100px;

    .popup-main {
      display: flex;
      .MuiDialogContent-root {
        display: flex;
        align-items: center;
        padding: 0;
      }

      .popup-descrip {
        width: 44%;
        padding: 35px;

        h2 {
          padding: 0;
          font-size: 32px;
          line-height: 35px;
          color: #000000;
          font-weight: bold;
          font-family: "Graphik-bold";
          margin-bottom: 10px;
        }

        p {
          font-size: 24px;
          color: #000;
          line-height: 1.3;
          font-family: 'Graphik';
          margin: 0;
          + p {
            font-weight: 600;
            margin: 25px 0 25px;
          }
        }

        .MuiFormControl-root {
          margin: 0 0 10px 0;

          fieldset {
            border: 1px solid #b3b3b3;
          }

          span {
            color: #000;
          }

          label {
            font-size: 20px;
            color: #999999;
            margin: 0;
          }
        }
      }

      .img-section {
        width: 100%;
        height: 279px;
        overflow: hidden;
      }

      .img-section img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;

const validationSchema = Yup.object({
  firstName: Yup.string()
    .min(2, "First name must be at least 2 characters")
    .required("First name is required"),
  lastName: Yup.string()
    .min(2, "Last name must be at least 2 characters")
    .required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .matches(
      /^(\+?\d{1,4}[\s-]?)?(\(?\d{1,3}\)?[\s-]?)?\d{1,4}[\s-]?\d{1,4}[\s-]?\d{1,4}$/,
      "Phone number is not valid"
    )
    .required("Phone number is required"),
});

function MailingComponent({ open, setOpenPopup, setInnerLoader, REFERRAL_CODE, innerLoader }) {
  const [showMessage, setShowMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleClose = useCallback(() => {
    setOpenPopup(false);
    setInnerLoader(false);
    localStorage.setItem("PopUpDispaly", "true");
  }, [setOpenPopup]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      setInnerLoader(true);
      api.getDiscountRequest({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        referral_code: REFERRAL_CODE ? REFERRAL_CODE : "",
      })
        .then((res) => {
          if (res?.status === 200) {
            setShowMessage(res?.data?.message);
            setTimeout(() => {
              setShowMessage();
              setOpenPopup(false);
            }, 3000);
            setInnerLoader(false);
          } else if (res?.status === 400) {
            setErrorMessage(res?.data?.message);
            setTimeout(() => {
              setErrorMessage();
            }, 3000);
            setInnerLoader(false);
          } else {
            setInnerLoader(false);
          }
        })
        .catch((err) => {
          setInnerLoader(false);
        });
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogStyle className="popup-main">
          <DialogContent>
            <button
              className="popup-close-button"
              onClick={handleClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "transparent",
                border: "none",
                fontSize: "16px",
                cursor: "pointer",
                zIndex: "1",
              }}
            >
              ✖
            </button>

            <div className="popup-descrip">
              <MuiDialogTitle>UNLOCK 10% OFF*</MuiDialogTitle>
              <Typography variant="body1" gutterBottom>
                Join our mailing list and save on your first order at Kaire.
              </Typography>

              <form onSubmit={formik.handleSubmit} style={{ marginTop: "20px" }}>
                <TextField
                  fullWidth
                  margin="normal"
                  // label="First Name"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  variant="outlined"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div style={{ color: "red" }}>
                    {formik.errors.firstName}
                  </div>
                )}

                <TextField
                  fullWidth
                  margin="normal"
                  // label="Last Name"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  variant="outlined"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div style={{ color: "red" }}>
                    {formik.errors.lastName}
                  </div>
                )}

                <TextField
                  fullWidth
                  margin="normal"
                  // label="Email Address"
                  type="email"
                  name="email"
                  placeholder="Email"
                  variant="outlined"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <div style={{ color: "red" }}>
                    {formik.errors.email}
                  </div>
                )}

                <TextField
                  fullWidth
                  margin="normal"
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  variant="outlined"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div style={{ color: "red" }}>
                    {formik.errors.phoneNumber}
                  </div>
                )}

                <div className="show-msg" style={{ color: "green" }}>
                  {showMessage}
                </div>
                <div className="show-msg" style={{ color: "red" }}>
                  {errorMessage}
                </div>

                <DialogActions>
                  <Button
                    className="main_btn_common"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!(formik.isValid && formik.dirty)}
                  >
                    Sign Up and Save
                  </Button>
                </DialogActions>
              </form>
            </div>

            <div className="img-section">
              <Image
                src="/images/popupimg.webp"
                alt="Popup"
                width={700}
                height={600}
              />
            </div>
            {innerLoader === true && (
              <div className="flexbox">
                <div className="">
                  <div className="bt-spinner"></div>
                </div>
              </div>
            )}
          </DialogContent>
        </DialogStyle>
      </Dialog>

    </div>
  );
}

export default MailingComponent;
