import AccordionComp from '@Components/Common/Accordion';
import Link from 'next/link';
import NumberFormat from 'react-number-format';
import { useRouter } from 'next/router'
import parse from 'html-react-parser';
import _ from 'lodash';
import CustomPagination from '@Components/Common/CustomPagination.jsx';
import { forwardRef, useState, useEffect, useRef, useCallback } from 'react';
import { Dialog, DialogContent, Button, Radio, Tooltip, IconButton } from '@material-ui/core';
import NumberFormatComp from '@Components/Common/NumberFormatComp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClassNames from 'classnames';

const AccordionSec = forwardRef(({ product, }, ref) => {
    return (
        <div className="accordin">
            {(() => {
                const data = [
                    { title: "Description", content: product?.products?.description, active: true, for: "desktop_view" },
                    { title: "Description", content: product?.products?.description, active: false, for: "mobile_view" },
                    { title: "Benefits", content: product?.products?.benefits, active: false, for: "desktop_view" },
                    { title: "Benefits", content: product?.products?.benefits, active: false, for: "mobile_view" },
                    // { title: "Ingredients", content: product?.products?.ingredients, active: false, for: "" },
                    { title: "Suggested Use", content: product?.products?.suggested_use, active: false, for: "" },
                    // { title: "Returns", content: product?.products?.return_product, active: false, for: "" }
                ];
                return data?.map((value, index) => (
                    <div key={index + 1} className={`${value.for ? value.for : ""} accordion` + index}>
                        {value?.content &&
                            <AccordionComp title={value.title} content={parse(String(value.content))} active={value?.active} ref={ref} />
                        }
                    </div>
                ))
            })()}
        </div>
    )
})

export default AccordionSec;


export const SecondSection = ({ data }) => {
    const { costtype, productCost, productSmartShip, minCartAutoshipCheck, smartShipCheck, setcosttype, id } = data;
    const router = useRouter();
    const findData = _.find(costtype, { id: id });
    const [selectedClass, setSelectedClass] = useState('AutoShip');

    const changeCostValue = useCallback((values) => {
        const availableData = _.reject(costtype, { id: findData?.id });
        setcosttype([...availableData, { id: id, value: values }]);
    }, [costtype, findData, id, setcosttype]);

    const handleRadioClick = (option) => {
        setSelectedClass(option);
        changeCostValue(option);
    };

    return (
        <div className="second_section">
            {[
                { option: "Normal", name: "One-Time Order", paragraph: "List Price", para1: "SmartShip Discount Applied" },
                { option: "AutoShip", name: "Subscribe & Save", paragraph: "Monthly Subscription", para1: "Monthly Subscription" }
            ].map(({ option, name, paragraph, para1 }, index) => (
                <div
                    className={ClassNames(`${option.toLowerCase()}_radio_container radio_class custom_radio`, {
                        selected_class: selectedClass === option,
                        add_to_the_class: (option === "Normal" && (minCartAutoshipCheck === "True" || smartShipCheck))
                    })}
                    key={index + 1}
                    onClick={() => handleRadioClick(option)}
                >
                    <div className="radio_section">
                        <Radio
                            checked={findData?.id === id && findData?.value === option}
                            onChange={() => handleRadioClick(option)}
                            value={option}
                            name="radio-buttons"
                            inputProps={{ 'aria-label': option }}
                        />
                    </div>
                    <div className="title_section">
                        <h3 className="order">{name}</h3>
                        {router?.asPath !== "/" && router?.asPath !== "/us/allProduct/" && (minCartAutoshipCheck === "True" || smartShipCheck) ?
                            <p className={option === "Normal" ? "green_code" : "black_code"}>
                                {para1}
                            </p> :
                            router?.asPath !== "/" && router?.asPath !== "/us/allProduct/" && <p> {paragraph}</p>
                        }
                    </div>

                    <div className={data?.section ? `allproduct_${option.toLowerCase()}` : "price_section"}>
                        {option === "Normal" ? (
                            <>
                                {minCartAutoshipCheck !== "True" && !smartShipCheck && (
                                    <NumberFormatComp value={productCost ? productCost : 0} />
                                )}
                                {(minCartAutoshipCheck === "True" || smartShipCheck) && (
                                    <>
                                        <NumberFormatComp
                                            value={productSmartShip ? productSmartShip : 0}
                                            render={(value) => (
                                                <Tooltip title="Smartship price"
                                                >
                                                    <div className="autoship_price_for_true">
                                                        {value} USD
                                                        <svg
                                                            stroke="currentColor"
                                                            fill="currentColor"
                                                            strokeWidth="0"
                                                            viewBox="0 0 192 512"
                                                            height="1em"
                                                            width="1em"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z"></path>
                                                        </svg>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        />
                                        {/* <span className="discount_smartship" style={{ color: 'green' }}>
                                            You Save ${parseFloat(productCost - productSmartShip).toFixed(2)}
                                        </span> */}
                                    </>
                                )}
                            </>
                        ) : (
                            <NumberFormatComp value={productSmartShip} />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};


// frequantly product section 
export const FrequentlyProducts = ({ data, cartNotify }) => {
    const router = useRouter();
    const { id, name, page } = router.query;
    const { addToCart, relatedData, minCartAutoshipCheck, costtype, setcosttype, smartShipCheck } = data;

    const [active, setActive] = useState(false);

    useEffect(() => {
        const sliceData = _.slice(_.reject(relatedData, { id: id }), 0, 2);
        const dataValue = [];
        _.forEach(sliceData, (data) => {
            if (!(_.findIndex(costtype, { id: data?.id }) > -1)) {
                dataValue.push({ id: data?.id, value: "Normal" });
            }
        });
        setcosttype([...costtype, ...dataValue])
    }, []);


    return (
        <div className="frequestion-section">
            <h3>Frequently Bought Together</h3>
            <div className="product-checkout">
                <ul>
                    {_.reject(relatedData, { id: id })?.slice(0, 2)?.map((value, index) => {
                        let names = value?.name.replaceAll('25%', '');
                        let catname1 = names.replaceAll('\t', '');
                        let catname = catname1.replaceAll(' ', '_');
                        return (
                            <li key={index + 1} onMouseEnter={() => setActive(value?.id)} onMouseLeave={() => setActive(null)}>
                                <div className="product-firxt">
                                    <div className="product_1">
                                        <Link href={`/${page}/product/${catname}/${value?.id}`}>
                                            <a className="thumbnail">
                                                <img src={`${process.env.API_URL}/${value?.product_images[0]?.['image']}`} />
                                                <h5>{value?.name}</h5>
                                            </a>
                                        </Link>
                                        <ArrowDropDownIcon className={ClassNames('down_icon', { "active": (active === value?.id) })} />
                                        {(active === value?.id) &&
                                            <>
                                                <SecondSection data={{
                                                    smartShipCheck,
                                                    costtype,
                                                    setcosttype,
                                                    id: value?.id,
                                                    minCartAutoshipCheck,
                                                    productCost: value?.cost_price,
                                                    productSmartShip: value?.autoship_cost_price
                                                }} />
                                                <button className={ClassNames("btn btn-cart", { 'disable_true': !(value?.is_stock_available === "True") })}
                                                    disabled={!(value?.is_stock_available === "True")}
                                                    onClick={() => addToCart(value?.id, 1)}>{
                                                        AddCardButtonText(value?.is_stock_available, costtype, value?.id)}
                                                </button>
                                                {cartNotify && <span className="error" style={{ color: 'green' }}>{cartNotify}</span>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </li>)
                        // }
                    })}
                </ul>
            </div>
        </div>
    )
}

//Add to cart button jsx
export const AddCardButtonText = (is_stock_available, costtype, id) => {
    if (is_stock_available === 'True') {
        const findData = _.find(costtype, { id: id });
        if (findData?.id === id && findData?.value === "AutoShip") {
            return 'Subscribe';
        }
        return 'Add to Cart'
    } else {
        return "Sold Out"
    }
}

//review section 
export const ReviewSection = (props) => {
    const [showPerPage, setShowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    // Get current posts
    const indexOfLastPost = currentPage * showPerPage;
    const indexOfFirstPost = indexOfLastPost - showPerPage;

    const { ForRatingStar, reviewData, setReviewData } = props;
    const currentPosts = _.reject(reviewData, (row) => row.is_approved !== true).slice(indexOfFirstPost, indexOfLastPost);
    return (
        <>
            {_.map(currentPosts, (data, index) => (
                <div className='client-div' key={index + 1} >
                    <div className="row client-review">
                        <div className="col-md-2">
                            <div className="profile-1">
                                <h6>{data?.user_name || "black right now"}</h6>
                                <div className="comment-stars">{ForRatingStar(+data?.review_ratings)}</div>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="comment">
                                <p>{data?.review_message}</p>
                            </div>
                        </div>
                        {/* <div className="col-md-2">
                        <div className="comment-stars">{ForRatingStar(+data?.review_ratings)}</div>
                    </div> */}
                    </div>
                </div>
            ))}
            <CustomPagination
                data={reviewData}
                showPerPage={showPerPage}
                currentPosts={currentPosts}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage} />
        </>
    )
};


// open on suppliment button click
export function SupplimentPopUp({ handleClose, open, image }) {
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                {
                    handleClose &&
                    <div className='crossImg'>
                        <IconButton aria-label="close" className="close_button" onClick={handleClose}>
                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="CloseIcon"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
                        </IconButton>
                    </div>
                }

                <DialogContent>
                    <img src={process.env.API_URL + image} style={{ width: "100%" }} />
                </DialogContent>
            </Dialog>
        </div>
    );
}

// list o\r  proven benifit section

export function UniqueDeliveryComp({ children, image, heading, proven_button_link, content, productName, seeMoreFunction }) {
    const route = useRouter();
    const store = route?.query?.page || "us";

    return (
        <div className="main_section_unique">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="image_and_title">
                            <div className="image">
                                <img src={`${process.env.API_URL}/${image}`} className="product_img" />
                            </div>
                            <div className="imageText">
                                {heading !== "" ? parse(String(heading)) : ""}
                                <div className='for_desktop_view'>
                                    {proven_button_link &&
                                        <Link href={`/${store}/proven_benefits`}>Proven Benefits </Link>
                                    }
                                    {children}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 point_section">

                        {content !== "" ? parse(String(content)) : ""}
                        <div className='for_mobile_view'>
                            {proven_button_link &&
                                <Link href={`/${store}/proven_benefits`}>Proven Benefits  </Link>
                            }
                            {children}
                        </div>
                        <p className='see_benifits' onClick={() => seeMoreFunction()} style={{ color: '#fff', cursor: 'pointer' }}>
                            See a full list of benefits in the description section.</p>
                    </div>

                </div>
            </div>
        </div >
    )
}