import React, { useCallback, useState } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import api from "@api/Apis";
import { useRouter } from "next/router";

const DialogStyle = styled.div`
  .MuiPaper-root {
    max-width: 1100px;
    .popup-main {
      display: flex;
      .MuiDialogContent-root {
        display: flex;
        padding: 0;
      }
      .popup-descrip {
        width: 44%;
        padding: 35px;
        h2 {
          padding: 0;
          font-size: 32px;
          line-height: 35px;
          color: #000000;
          font-weight: bold;
          font-family: "Graphik-bold";
          margin-bottom: 10px;
        }
        p {
          font-size: 24px;
          color: #000;
          line-height: 1.3;
          font-family: 'Graphik';
          margin: 0;
          + p {
            font-weight: 600;
            margin: 25px 0 25px;
          }
        }
        .MuiFormControl-root {
          margin: 0 0 10px 0;
          fieldset {
            border: 1px solid #b3b3b3;
          }
          span {
            color: #000;
          }
          label {
            font-size: 20px;
            color: #999999;
            margin: 0;
          }
        }
      }

      .img-section {
        overflow: hidden;
        width: 100%;
        height: 279px;
      }

      .img-section img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

function ClaimGiftPopup({
  openClaimGift,
  setOpenClaimGift,
  setInnerLoader,
  REFERRAL_CODE,
  USER_DATA,
  innerLoader
}) {
  const router = useRouter();

  const [showMessage, setShowMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = useCallback(() => {
    setOpenClaimGift(false);
    setInnerLoader(false);
  }, [setOpenClaimGift]);

  const formik = useFormik({
    initialValues: {
      email: USER_DATA ? USER_DATA || "" : "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      setInnerLoader(true);
      api.getDiscountRequest({
        email: values.email,
        referral_code: REFERRAL_CODE,
      })
        .then((res) => {
          if (res?.status === 200) {
            setShowMessage(res?.data?.message);
            setTimeout(() => {
              setShowMessage();
              setOpenClaimGift(false);
            }, 3000);

            setInnerLoader(false);
          } else if (res?.status === 400) {
            setErrorMessage(res?.data?.message);
            setTimeout(() => {
              setErrorMessage();
            }, 3000);
            setInnerLoader(false);
          } else {
            setInnerLoader(false);
          }
        })
        .catch(() => {
          setInnerLoader(false);
        });
    },
  });

  return (
    <div>
      <Dialog open={openClaimGift} onClose={handleClose}>
        <DialogStyle className="popup-main">
          <DialogContent>
            <button
              className="popup-close-button"
              onClick={handleClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "transparent",
                border: "none",
                fontSize: "16px",
                cursor: "pointer",
                zIndex: "1",
              }}
            >
              ✖
            </button>

            <div className="popup-descrip">
              <MuiDialogTitle>YOU’VE GOT $15 TO SHOP!</MuiDialogTitle>
              <Typography variant="body1" gutterBottom>
                Thanks to a great friend, you have a $15 discount to shop at Kaire. Enter your email to get the referral code:
              </Typography>

              <form onSubmit={formik.handleSubmit} style={{ marginTop: "20px" }}>
                <TextField
                  fullWidth
                  margin="normal"
                  type="email"
                  name="email"
                  placeholder="Email"
                  variant="outlined"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <div style={{ color: "red" }}>
                    {formik.errors.email}
                  </div>
                )}

                <div className="show-msg" style={{ color: "green" }}>
                  {showMessage}
                </div>
                <div className="show-msg" style={{ color: "red" }}>
                  {errorMessage}
                </div>

                <DialogActions>
                  <Button
                    className="main_btn_common"
                    type="submit"
                    variant="contained"
                    color="primary"
                  // disabled={!(formik.isValid && formik.dirty)}
                  >
                    Claim Your $15
                  </Button>
                </DialogActions>
              </form>
            </div>

            <div className="img-section">
              <img
                src="../images/referral-girl.jpg"
                alt="Popup"
                width="500"
                height="auto"
                loading="lazy"
              />
            </div>
            {innerLoader === true && (
              <div className="flexbox">
                <div className="">
                  <div className="bt-spinner"></div>
                </div>
              </div>
            )}
          </DialogContent>
        </DialogStyle>
      </Dialog>
    </div>
  );
}

export default ClaimGiftPopup;
